const iframes = document.querySelectorAll('.iframe_class');

iframes.forEach((iframe) => {
    if (!iframe.classList.contains("full_height")) {
        iframe.scrolling = "no";
    }

    iframe.addEventListener('load', function() {
        startIFrameCom(this);
    }, false);
})

function startIFrameCom(myIFrame) {
    window.addEventListener('message', function(e) {
        resizeIFrame(document.getElementById(e.data.iframeid), e.data.iframeheight);
    }, false);

    myIFrame.contentWindow.postMessage({iframeid: myIFrame.id}, "*");

    var resizeIFrame = function(iframe, new_scroll_height) {
        if (iframe) {
            var ua = navigator.userAgent.toLowerCase();
            var offset = 50;

            if (ua.indexOf('safari') != -1) {
                if (ua.indexOf('chrome') > -1) {
                    offset = 50;
                } else {
                    offset = 200;
                }
            }

            if (ua.match(/iPad/i) || ua.match(/iPhone/i)) {
                offset = 200;
            }

            var old_scroll_height = iframe.scrollHeight;
            var new_scroll_height_adjusted = new_scroll_height + offset;

            iframe.style.height = new_scroll_height_adjusted + 'px';
        }
    };
}
